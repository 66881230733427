import debug from 'debug';
import PropTypes from 'prop-types';
import React from 'react';
import { Search } from '@mulesoft/exchange-search-components';
import { ANGFilters } from '@mulesoft/exchange-search-common';
import * as constants from '~/utils/ang/constants';
import styles from './ANGSearch.css';

const log = debug('exchange:ang-search:component');

const searchFilters = [
  'types',
  'organizations',
  'categories',
  'tags',
  'statuses'
];

class ANGSearch extends React.PureComponent {
  static propTypes = {
    boostConfiguration: PropTypes.arrayOf(PropTypes.object),
    defaultSort: PropTypes.string,
    onSearch: PropTypes.func,
    onResultsChange: PropTypes.func,
    sort: PropTypes.string,
    isCustomFieldsEnabled: PropTypes.bool,
    filters: PropTypes.instanceOf(ANGFilters).isRequired
  };

  static defaultProps = {
    onSearch: () => {}
  };

  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
    this.searchFilters = props.isCustomFieldsEnabled
      ? [...searchFilters, 'customFields']
      : searchFilters;
  }

  state = {
    angFilters: this.props.filters
  };

  static getDerivedStateFromProps(props, state) {
    if (state.prevANGFilters !== props.filters) {
      return {
        prevANGFilters: props.filters,
        angFilters: props.filters
      };
    }

    return null;
  }

  handleReady = () => {
    this.searchRef?.current?.search();
  };

  handleChange = (angFilters) => {
    this.setState({
      angFilters
    });
  };

  handleSearch = (angFilters, angState, angConfig, sort) => {
    const { onSearch } = this.props;

    return onSearch(angFilters, sort);
  };

  render() {
    const { boostConfiguration, defaultSort, sort, onResultsChange } =
      this.props;
    const { angFilters } = this.state;

    log('using state', angFilters.getQuery());

    return (
      <div className={styles.search}>
        <Search
          angFilters={angFilters}
          ref={this.searchRef}
          onFiltersChange={this.handleChange}
          sort={sort}
          sortOnClear={defaultSort}
          boostConfiguration={boostConfiguration}
          maxResults={constants.MAX_ANG_STREAM_SEARCH_RESULTS}
          filters={this.searchFilters}
          onSearch={this.handleSearch}
          onResultsChange={onResultsChange}
          onReady={this.handleReady}
          placeholder="Search assets"
        />
      </div>
    );
  }
}

export default ANGSearch;
