import { format } from 'url';
import debug from 'debug';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { withAssetTypesConfiguration } from '@mulesoft/exchange-react-hooks';
import { selectors as featureFlagsSelectors } from '~/domains/featureFlags';
import {
  buildFromLocation,
  getTargetQuery,
  getBoostConfiguration
} from '~/utils/ang/ANGFilters';
import RootStoreContext from '~/contexts/RootStoreContext';
import {
  actions as searchActions,
  selectors as searchSelectors
} from '~/domains/search';
import { ANG_CUSTOM_FIELDS_ENABLED } from '~/domains/featureFlags/keys';
import { selectors as commonSelectors } from '~/domains/common';
import { getDefaultSort } from '~/utils/ang/sorting';
import { parseSearch, equalsQuery } from '~/utils/location';
import { ANG_EXTERNAL_QUERY_PARAMS } from '~/utils/navigation/constants';
import * as objectsUtils from '~/utils/objects';
import ANGSearch from './ANGSearch';

const log = debug('exchange:ang-search:container');

const mapStateToProps = (state, { assetTypesConfiguration, location }) => {
  const profile = commonSelectors.profile(state);
  const isAuthenticated = !!profile;
  const rootOrganizations = searchSelectors.rootOrganizations(state);
  const boostConfiguration = getBoostConfiguration(location.search);
  const isCustomFieldsEnabled = featureFlagsSelectors.featureFlag(
    state,
    ANG_CUSTOM_FIELDS_ENABLED
  );
  const query = parseSearch(location.search);
  const homeQuery = commonSelectors.homeQuery(state);
  const defaultSort = getDefaultSort({ isAuthenticated, show: query.show });
  const filters = buildFromLocation(
    location,
    assetTypesConfiguration,
    rootOrganizations,
    profile
  );

  return {
    boostConfiguration,
    isCustomFieldsEnabled,
    filters,
    homeQuery,
    profile,
    rootOrganizations,
    defaultSort,
    sort: query.sort ?? defaultSort ?? 'last'
  };
};

const mapDispatchToProps = (dispatch, { location }) => ({
  onFilterUpdate: (facets) => {
    dispatch(searchActions.updateFacets(facets));
  },
  onResultsChange: (results) => {
    dispatch(searchActions.changeResults(results));
  },
  onSearch: (filters, sort, profile, rootOrganizations, homeQuery) => {
    log('received filters', filters.getQuery());

    const query = parseSearch(location.search);
    const newQuery = {
      ...getTargetQuery(filters.getQuery(), profile, rootOrganizations),
      ...objectsUtils.pick(query, ...ANG_EXTERNAL_QUERY_PARAMS),
      ...(sort ? { sort } : {})
    };
    const cleanQuery = Object.keys(newQuery).reduce((accum, current) => {
      if (!newQuery[current]) {
        return accum;
      }

      if (Array.isArray(newQuery[current]) && newQuery[current].length === 0) {
        return accum;
      }

      return {
        ...accum,
        [current]: newQuery[current]
      };
    }, {});
    const newURL = format({ pathname: location.pathname, query: cleanQuery });

    if (newURL !== `${location.pathname}${location.search}`) {
      log('dispatching new query state', newQuery);
      dispatch(push(newURL));
    }

    log('dispatching search');

    const locationQuery = objectsUtils.omit(cleanQuery, 'view');
    const currentHomeQuery = homeQuery && objectsUtils.omit(homeQuery, 'view');
    const hasHomeQueryChanged = !equalsQuery(locationQuery, currentHomeQuery);

    if (hasHomeQueryChanged) {
      return 'not-handled';
    }

    return 'handled';
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSearch: (filters, sort) =>
    dispatchProps.onSearch(
      filters,
      sort,
      stateProps.profile,
      stateProps.rootOrganizations,
      stateProps.homeQuery
    )
});

export default compose(
  withAssetTypesConfiguration,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    context: RootStoreContext
  })
)(ANGSearch);
